import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-format';
import { Formik, ErrorMessage } from 'formik';
import { RESX_GLOBAL, LOGGLY_LEVEL_ERROR, MIXPANEL_INTENT, MIXPANEL_CONTENT } from '../../../Constants';
import resx from '../../../utilities/resx';
import LogglyLogger from '../../../API/Loggly';
import AuthService from '../../../API/AuthService';
import Mixpanel from '../../../API/Mixpanel';
import { resetPasswordEmailValidation } from '../resetPasswordValidation';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import { withMessage } from '../../Common/Message';

const ResetPasswordEmailForm = props => (
    <Formik
        initialValues={{ Email: '' }}
        onSubmit={values => {
            const data = {
                passwordChange: {
                    Email: values.Email,
                    Request: 'true'
                }
            };
            Mixpanel.add(MIXPANEL_INTENT, {
                type: 'requestpasswordresetemail',
                context: 'generic'
            });
            AuthService.requestPasswordChange(data)
                .then(response => {
                    if (
                        response.data &&
                        response.data.RequestPasswordChangeResult &&
                        response.data.RequestPasswordChangeResult.Success
                    ) {
                        props.message.addMessage({
                            message: format(resx(RESX_GLOBAL, 'EMAIL_SENT'), values.Email),
                            type: 'success',
                            autoDelete: false
                        });
                    } else {
                        props.message.addMessage({
                            message: response.data.RequestPasswordChangeResult.Message,
                            type: 'error',
                            autoDelete: false
                        });
                    }
                })
                .catch(error => {
                    LogglyLogger.add(
                        LOGGLY_LEVEL_ERROR,
                        'RequestPasswordChange service failure in handleSendEmailSubmit',
                        error
                    );
                    props.message.addMessage({
                        message: resx(RESX_GLOBAL, 'PASSWORD_CHANGE_REQUEST_ERROR'),
                        type: 'error',
                        autoDelete: false
                    });
                });
        }}
        validationSchema={resetPasswordEmailValidation}
    >
        {props => {
            const { values, handleChange, handleBlur, handleSubmit } = props;

            return (
                <React.Fragment>
                    <form method="post" onSubmit={handleSubmit} className="formV2">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: format(
                                    resx(RESX_GLOBAL, 'RESET_PASSWORD_STEP_1'),
                                    resx(RESX_GLOBAL, 'RESET_PASSWORD_PAGE_SUBMIT_BUTTON')
                                )
                            }}
                        />
                        <Input
                            title={resx(RESX_GLOBAL, 'TOOLBAR_EMAIL_PLACEHOLDER')}
                            type="email"
                            name="Email"
                            placeholder={resx(RESX_GLOBAL, 'TOOLBAR_EMAIL_PLACEHOLDER')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Email}
                        />
                        <ErrorMessage name="Email">
                            {msg => <div className="error error-message">{msg}</div>}
                        </ErrorMessage>

                        <Button
                            type="submit"
                            text={resx(RESX_GLOBAL, 'RESET_PASSWORD_PAGE_SUBMIT_BUTTON')}
                            className="re-btn"
                        />

                        <p dangerouslySetInnerHTML={{ __html: resx(RESX_GLOBAL, 'RESET_PASSWORD_STEP_2') }} />
                        <p>{resx(RESX_GLOBAL, 'RESET_PASSWORD_STEP_3')}</p>
                        <div className="line more-help">
                            <a
                                href="mailto:support@resaas.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Still need help?
                            </a>
                        </div>
                    </form>
                </React.Fragment>
            );
        }}
    </Formik>
);

ResetPasswordEmailForm.propTypes = {
    message: PropTypes.object
};

export default withMessage(ResetPasswordEmailForm);
