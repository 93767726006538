import React, { Component } from 'react';
import { RESX_GLOBAL } from '../../../Constants';
import GeneralUtility from '../../../utilities/generalUtility';
import resx from '../../../utilities/resx';
import RequestForm from './RequestForm';
import PasswordForm from './ResetPasswordForm';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasResetQueryString: false,
            emailAddress: '',
            requestId: null
        };
    }

    componentDidMount = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const requestId = queryParams.get('RequestId');
        const email = queryParams.get('Email');

        if (requestId && email) {
            this.setState({
                emailAddress: email,
                hasResetQueryString: true,
                requestId: requestId
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="re-container-outer re-container">
                    <h1>{resx(RESX_GLOBAL, 'RESET_PASSWORD_PAGE_HEADING1')}</h1>
                    {this.state.hasResetQueryString ? (
                        <PasswordForm email={this.state.emailAddress} requestId={this.state.requestId} />
                    ) : (
                        <RequestForm />
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default ResetPassword;
