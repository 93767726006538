import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ResetPassword from './Container';
import { MessageProvider } from '../Common/Message';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <MessageProvider>
                <ResetPassword />
            </MessageProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.querySelector('#component-reset-password')
);
