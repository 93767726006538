import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MIXPANEL_LAND } from '../../Constants';
import ResetContainer from './Generic/Container';
import ResetSSOContainer from './SSO/Container';

const ResetApp = () => {
    return (
        <React.StrictMode>
            <Switch>
                <Route exact path="/reset">
                    <ResetContainer />
                </Route>
                <Route exact path="/reset/sso" render={() => <ResetSSOContainer />} />
                <Route exact path="/reset/sso/:ssoprovider" render={() => <ResetSSOContainer />} />
            </Switch>
        </React.StrictMode>
    );
};
export default ResetApp;
