import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import format from 'string-format';
import LogglyLogger from '../../../API/Loggly';
import AuthService from '../../../API/AuthService';
import Mixpanel from '../../../API/Mixpanel';
import ErrorMessage from '../../Common/ErrorMessage';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import { withMessage } from '../../Common/Message';
import GeneralUtility from '../../../utilities/generalUtility';
import resx from '../../../utilities/resx';
import {
    RESX_GLOBAL,
    LOGGLY_LEVEL_ERROR,
    MIXPANEL_LAND,
    MIXPANEL_INTENT,
    MIXPANEL_CONTENT,
    REGEX_EMAIL
} from '../../../Constants';
import Validation from '../Validation';

const RequestForm = props => {
    let descriptionStep1, emailPrompt, messageType;
    const { ssoprovider } = useParams();
    const [email, setEmail] = useState('');
    const [showSuccessStep, setShowSuccessStep] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [validationMessages, setValidationMessages] = useState({
        Email: ''
    });

    const initialValidationMessagesState = {
        Email: ''
    };

    const [validate, setValidate] = useState({
        validate: false,
        fields: []
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const em = searchParams.get('email');
        if (em) {
            setEmail(em);
        }
    }, []);

    // for live validation
    useEffect(() => {
        if (validate.validate) {
            const newState = Validation(validate.fields, { email: email });

            if (newState) {
                setValidationMessages(prevState => ({ ...prevState, ...newState }));
            } else {
                setValidate({
                    validate: false,
                    fields: []
                });
            }
        }
    }, [email]);

    const handleInputChange = e => {
        setEmail(e.currentTarget.value);
    };

    const handlerequestPasswordChange = () => {
        const fields = ['Email'];
        const newState = Validation(fields, { email: email });
        if (!newState.allFieldsValidated) {
            setValidationMessages(prevState => ({ ...prevState, ...newState }));
            setValidate({
                validate: true,
                fields: fields
            });
        } else {
            const updateState = { ...initialValidationMessagesState };
            setValidationMessages(prevState => ({ ...prevState, ...updateState }));
            setSubmitting(true);
            Mixpanel.add(MIXPANEL_INTENT, {
                type: 'requestssopasswordresetemail',
                context: ssoprovider ? ssoprovider : 'sso'
            });
            const data = {
                passwordChange: {
                    Email: email,
                    Request: 'true',
                    MessageType: messageType
                }
            };
            AuthService.requestPasswordChange(data)
                .then(response => {
                    if (
                        response.data &&
                        response.data.RequestPasswordChangeResult &&
                        response.data.RequestPasswordChangeResult.Success
                    ) {
                        setShowSuccessStep(true);
                        Mixpanel.add(MIXPANEL_LAND, { pageType: 'resetPassword-email-sent' });
                    } else {
                        props.message.addMessage({
                            message: response.data.RequestPasswordChangeResult.Message,
                            type: 'error',
                            autoDelete: false
                        });
                    }
                    setSubmitting(false);
                })
                .catch(error => {
                    LogglyLogger.add(
                        LOGGLY_LEVEL_ERROR,
                        'RequestPasswordChange service failure in handleSendEmailSubmit',
                        error
                    );
                    props.message.addMessage({
                        message: resx(RESX_GLOBAL, 'PASSWORD_CHANGE_REQUEST_ERROR'),
                        type: 'error',
                        autoDelete: false
                    });
                    setSubmitting(false);
                });
        }
    };

    switch (ssoprovider) {
        case 'remax':
            descriptionStep1 = resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_DESCRIPTION_REMAX');
            emailPrompt = resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_ENTER_EMAIL_REMAX');
            messageType = 2;
            break;

        default:
            descriptionStep1 = resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_DESCRIPTION');
            emailPrompt = resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_ENTER_EMAIL');
            messageType = 1;
            break;
    }

    return (
        <div className="re-reset-password-container">
            {showSuccessStep ? (
                <React.Fragment>
                    <h1 className="re-reset-password-header">{resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_STEP2')}</h1>
                    <div className="re-reset-password-desc" data-testid="success-message">
                        <div className="re-message">
                            {format(resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_STEP2_BTN'), email)}
                        </div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_STEP2_INSTRUCTION1')
                            }}
                        />
                        <p>{resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_STEP2_INSTRUCTION2')}</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_STEP2_INSTRUCTION3')
                            }}
                        />
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h1 className="re-reset-password-header">{resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_HEADER')}</h1>
                    <div
                        className="re-reset-password-desc"
                        dangerouslySetInnerHTML={{
                            __html: descriptionStep1
                        }}
                    ></div>

                    <div className="re-reset-password-step">{resx(RESX_GLOBAL, 'RESET_PASSWORD_REQUEST_STEP1')}</div>
                    <p>{emailPrompt}</p>

                    <form className="formV2 formV2-fieldset re-reset-password-form">
                        <Input
                            title={resx(RESX_GLOBAL, 'EMAIL')}
                            type="email"
                            name="Email"
                            id="Email"
                            pattern={REGEX_EMAIL}
                            required={true}
                            value={email}
                            dataTestid="email"
                            onChange={handleInputChange}
                        />
                        <ErrorMessage validationMessages={validationMessages} fieldName="Email" />
                    </form>
                    <div className="re-reset-password-btn">
                        <Button
                            className={`re-btn ${submitting ? 'submitting' : ''}`}
                            text="Next"
                            onClick={handlerequestPasswordChange}
                            dataTestid="requestformbtn"
                        />
                    </div>
                </React.Fragment>
            )}
            <hr className="re-reset-password-hr" />
            <div className="re-reset-password-login">
                <p>
                    <strong>{resx(RESX_GLOBAL, 'RESET_PASSWORD_CREATED_PASSWORD')}</strong>
                </p>
                <p>
                    <a href="/login">{resx(RESX_GLOBAL, 'RESET_PASSWORD_LOGIN')}</a>
                </p>
            </div>
        </div>
    );
};

RequestForm.propTypes = {
    message: PropTypes.object
};

export default withMessage(RequestForm);
