import resx from '../../utilities/resx';
import { RESX_GLOBAL } from '../../Constants';
import GeneralUtility from '../../utilities/generalUtility';

const validationStrings = {
    Password: {
        valueMissing: resx(RESX_GLOBAL, 'SIGNUP2_ERROR_PLZ_ENTER_PASSWORD'),
        patternMismatch: resx(RESX_GLOBAL, 'SIGNUP2_ERROR_PASSWORD_TOO_SHORT')
    },
    ConfirmPassword: {
        valueMissing: resx(RESX_GLOBAL, 'SIGNUP2_ERROR_PLZ_ENTER_PASSWORD'),
        patternMismatch: resx(RESX_GLOBAL, 'SIGNUP2_ERROR_PASSWORD_TOO_SHORT')
    },
    Email: {
        patternMismatch: resx(RESX_GLOBAL, 'SIGNUP2_ERROR_PLZ_ENTER_VALID_EMAIL'),
        valueMissing: resx(RESX_GLOBAL, 'SIGNUP2_ERROR_PLZ_ENTER_VALID_EMAIL')
    }
};

const Validation = (fields, values) => {
    const results = GeneralUtility.validateFields(fields, validationStrings);
    if (values.ConfirmPassword && values.ConfirmPassword !== values.Password) {
        results.ConfirmPassword = resx(RESX_GLOBAL, 'PASSWORD_NOT_MATCH');
        results.allFieldsValidated = false;
    }
    return results;
};

export default Validation;
