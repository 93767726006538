import * as Yup from 'yup';
import resx from '../../utilities/resx';
import { RESX_GLOBAL } from '../../Constants';

export const resetPasswordEmailValidation = Yup.object().shape({
    Email: Yup.string()
        .email(resx(RESX_GLOBAL, 'REGISTER_VALIDATION_EMAIL_INVALID'))
        .required(resx(RESX_GLOBAL, 'REGISTER_VALIDATION_EMAIL_INVALID'))
});

export const resetPasswordValidation = Yup.object().shape({
    Email: Yup.string()
        .email(resx(RESX_GLOBAL, 'SIGNUP2_ERROR_PLZ_ENTER_VALID_EMAIL'))
        .required(resx(RESX_GLOBAL, 'SIGNUP2_ERROR_PLZ_ENTER_VALID_EMAIL')),
    Password: Yup.string()
        .min(6, resx(RESX_GLOBAL, 'REGISTER_VALIDATION_PW_MIN_SIX_CHAR'))
        .required(resx(RESX_GLOBAL, 'REGISTER_VALIDATION_PW_MIN_SIX_CHAR')),
    ConfirmPassword: Yup.string()
        .min(6, resx(RESX_GLOBAL, 'REGISTER_VALIDATION_PW_MIN_SIX_CHAR'))
        .oneOf([Yup.ref('Password'), null], resx(RESX_GLOBAL, 'PASSWORD_NOT_MATCH'))
        .required(resx(RESX_GLOBAL, 'REGISTER_VALIDATION_PW_MIN_SIX_CHAR'))
});
