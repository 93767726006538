import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-format';
import { Formik, ErrorMessage } from 'formik';
import { RESX_GLOBAL, LOGGLY_LEVEL_ERROR, MIXPANEL_INTENT, MIXPANEL_CONTENT } from '../../../Constants';
import resx from '../../../utilities/resx';
import LogglyLogger from '../../../API/Loggly';
import AuthService from '../../../API/AuthService';
import Mixpanel from '../../../API/Mixpanel';
import { resetPasswordValidation } from '../resetPasswordValidation';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import { withMessage } from '../../Common/Message';

const ResetPasswordForm = props => (
    <Formik
        initialValues={{ Email: props.email, Password: '', ConfirmPassword: '' }}
        onSubmit={values => {
            const data = {
                passwordChange: {
                    Email: values.Email,
                    RequestId: props.requestId,
                    Password: values.Password
                }
            };
            Mixpanel.add(MIXPANEL_INTENT, {
                type: 'savepassword',
                context: 'generic'
            });
            AuthService.requestPasswordChange(data)
                .then(response => {
                    if (
                        response.data &&
                        response.data.RequestPasswordChangeResult &&
                        response.data.RequestPasswordChangeResult.Success
                    ) {
                        props.message.addMessage({
                            message: resx(RESX_GLOBAL, 'RESET_PASSWORD_SUCCESS'),
                            type: 'success',
                            autoDelete: false
                        });
                        Mixpanel.add(MIXPANEL_CONTENT, { content: 'password', context: 'generic' });
                    } else {
                        const responseMessage = response.data.RequestPasswordChangeResult.Message;
                        const finalMessage =
                            responseMessage === 'undefined'
                                ? format(resx(RESX_GLOBAL, 'INVALID_PASSWORD_TOKEN'))
                                : responseMessage;
                        props.message.addMessage({ message: finalMessage, type: 'error', autoDelete: false });
                    }
                })
                .catch(error => {
                    LogglyLogger.add(
                        LOGGLY_LEVEL_ERROR,
                        'RequestPasswordChange service failure in handleResetPasswordSubmit',
                        error
                    );
                    props.message.addMessage({
                        message: resx(RESX_GLOBAL, 'PASSWORD_CHANGE_REQUEST_ERROR'),
                        type: 'error',
                        autoDelete: false
                    });
                });
        }}
        validationSchema={resetPasswordValidation}
    >
        {props => {
            const { values, handleChange, handleBlur, handleSubmit } = props;

            return (
                <React.Fragment>
                    <form method="post" onSubmit={handleSubmit} className="formV2">
                        <p>{resx(RESX_GLOBAL, 'RESET_PASSWORD_PAGE_HEADING3_2')}</p>

                        <Input
                            title={resx(RESX_GLOBAL, 'TOOLBAR_EMAIL_PLACEHOLDER')}
                            type="email"
                            name="Email"
                            placeholder={resx(RESX_GLOBAL, 'TOOLBAR_EMAIL_PLACEHOLDER')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Email}
                            disabled={true}
                        />
                        <ErrorMessage name="Email">
                            {msg => <div className="error error-message">{msg}</div>}
                        </ErrorMessage>

                        <Input
                            title={resx(RESX_GLOBAL, 'RESET_PASSWORD_PAGE_PASSWORD_LABEL')}
                            type="password"
                            name="Password"
                            placeholder={resx(RESX_GLOBAL, 'RESET_PASSWORD_PAGE_PASSWORD_LABEL_SHORT')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Password}
                        />
                        <ErrorMessage name="Password">
                            {msg => <div className="error error-message">{msg}</div>}
                        </ErrorMessage>

                        <Input
                            title={resx(RESX_GLOBAL, 'RESET_PASSWORD_PAGE_RE_ENTER_PASSWORD_LABEL')}
                            type="password"
                            name="ConfirmPassword"
                            placeholder={resx(RESX_GLOBAL, 'RESET_PASSWORD_PAGE_RE_ENTER_PASSWORD_LABEL_SHORT')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ConfirmPassword}
                        />
                        <ErrorMessage name="ConfirmPassword">
                            {msg => <div className="error error-message">{msg}</div>}
                        </ErrorMessage>

                        <Button
                            type="submit"
                            text={resx(RESX_GLOBAL, 'RESET_PASSWORD_PAGE_RESET_PASSWORD_SUBMIT_BUTTON')}
                            className="re-btn"
                        />
                    </form>
                </React.Fragment>
            );
        }}
    </Formik>
);

ResetPasswordForm.propTypes = {
    email: PropTypes.string,
    requestId: PropTypes.string,
    message: PropTypes.object
};

export default withMessage(ResetPasswordForm);
