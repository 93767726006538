import React, { useEffect, useState } from 'react';
import { RESX_GLOBAL } from '../../../Constants';
import GeneralUtility from '../../../utilities/generalUtility';
import resx from '../../../utilities/resx';
import RequestForm from './RequestForm';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const requestId = queryParams.get('RequestId');
    const email = queryParams.get('Email');

    return (
        <React.StrictMode>
            {requestId && email ? <ResetPasswordForm email={email} requestId={requestId} /> : <RequestForm />}
        </React.StrictMode>
    );
};

export default ResetPassword;
