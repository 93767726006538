import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import format from 'string-format';
import LogglyLogger from '../../../API/Loggly';
import AuthService from '../../../API/AuthService';
import Mixpanel from '../../../API/Mixpanel';
import ErrorMessage from '../../Common/ErrorMessage';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import { withMessage } from '../../Common/Message';
import GeneralUtility from '../../../utilities/generalUtility';
import resx from '../../../utilities/resx';
import { RESX_GLOBAL, LOGGLY_LEVEL_ERROR, MIXPANEL_LAND, MIXPANEL_INTENT, MIXPANEL_CONTENT } from '../../../Constants';
import Validation from '../Validation';

const PasswordForm = props => {
    let descriptionStep3;
    const { ssoprovider } = useParams();
    const [passwords, setPasswords] = useState({ Password: '', ConfirmPassword: '' });
    const [showSuccessStep, setShowSuccessStep] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [validationMessages, setValidationMessages] = useState({
        Password: '',
        ConfirmPassword: ''
    });

    const initialValidationMessagesState = {
        Password: '',
        ConfirmPassword: ''
    };

    const [validate, setValidate] = useState({
        validate: false,
        fields: []
    });

    const loginURL = `/login?em=${encodeURIComponent(props.email)}`;

    // for live validation
    useEffect(() => {
        if (validate.validate) {
            const newState = Validation(validate.fields, passwords);

            if (newState) {
                setValidationMessages(prevState => ({ ...prevState, ...newState }));
            } else {
                setValidate({
                    validate: false,
                    fields: []
                });
            }
        }
    }, [passwords]);

    const handleInputChange = e => {
        const newState = {};
        const targetName = e.currentTarget.name;
        newState[targetName] = e.currentTarget.value;
        setPasswords(prevState => ({ ...prevState, ...newState }));
    };

    const handleResetPassword = () => {
        const fields = ['Password', 'ConfirmPassword'];
        const newState = Validation(fields, passwords);
        if (!newState.allFieldsValidated) {
            setValidationMessages(prevState => ({ ...prevState, ...newState }));
            setValidate({
                validate: true,
                fields: fields
            });
        } else {
            const updateState = { ...initialValidationMessagesState };
            setValidationMessages(prevState => ({ ...prevState, ...updateState }));
            setSubmitting(true);
            Mixpanel.add(MIXPANEL_INTENT, {
                type: 'savessopassword',
                context: ssoprovider ? ssoprovider : 'sso'
            });
            const data = {
                passwordChange: {
                    Email: props.email,
                    RequestId: props.requestId,
                    Password: passwords.Password
                }
            };
            AuthService.requestPasswordChange(data)
                .then(response => {
                    if (
                        response.data &&
                        response.data.RequestPasswordChangeResult &&
                        response.data.RequestPasswordChangeResult.Success
                    ) {
                        setShowSuccessStep(true);
                        Mixpanel.add(MIXPANEL_LAND, { pageType: 'resetPassword-password-set' });
                        Mixpanel.add(MIXPANEL_CONTENT, {
                            content: 'password',
                            context: ssoprovider ? ssoprovider : 'sso'
                        });
                        GeneralUtility.setCookie('ssomigrationcomplete', ssoprovider ? ssoprovider : 'sso', 6 * 30);
                    } else {
                        const responseMessage = response.data.RequestPasswordChangeResult.Message;
                        const finalMessage =
                            responseMessage === 'undefined'
                                ? format(resx(RESX_GLOBAL, 'INVALID_PASSWORD_TOKEN'))
                                : responseMessage;
                        props.message.addMessage({ message: finalMessage, type: 'error', autoDelete: false });
                    }
                    setSubmitting(false);
                })
                .catch(error => {
                    LogglyLogger.add(
                        LOGGLY_LEVEL_ERROR,
                        'RequestPasswordChange service failure in handleResetPassword for sso',
                        error
                    );
                    props.message.addMessage({
                        message: resx(RESX_GLOBAL, 'PASSWORD_CHANGE_REQUEST_ERROR'),
                        type: 'error',
                        autoDelete: false
                    });
                    setSubmitting(false);
                });
        }
    };

    switch (ssoprovider) {
        case 'remax':
            descriptionStep3 = resx(RESX_GLOBAL, 'RESET_PASSWORD_FORM_STEP3_DESCRIPTION2_REMAX');
            break;

        default:
            descriptionStep3 = resx(RESX_GLOBAL, 'RESET_PASSWORD_FORM_STEP3_DESCRIPTION2');
            break;
    }

    return (
        <div className="re-reset-password-container">
            {showSuccessStep ? (
                <React.Fragment>
                    <h1 className="re-icon re-icon-lg re-icon-green re-icon-check-circle re-reset-password-header">
                        {resx(RESX_GLOBAL, 'RESET_PASSWORD_FORM_SUCCESS')}
                    </h1>
                    <div className="re-reset-password-desc">
                        <p>{resx(RESX_GLOBAL, 'RESET_PASSWORD_FORM_SUCCESS_MESSAGE_1')}</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: format(resx(RESX_GLOBAL, 'RESET_PASSWORD_FORM_SUCCESS_MESSAGE_2'), loginURL)
                            }}
                        ></p>
                        <p>
                            <a className="re-btn" href={loginURL} data-testid="login-link">
                                {resx(RESX_GLOBAL, 'RESET_PASSWORD_LOGIN_NOW')}
                            </a>
                        </p>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h1 className="re-reset-password-header">{resx(RESX_GLOBAL, 'RESET_PASSWORD_FORM_STEP3')}</h1>
                    <div className="re-reset-password-desc">
                        <p>{resx(RESX_GLOBAL, 'RESET_PASSWORD_FORM_STEP3_DESCRIPTION1')}</p>
                        <p>{descriptionStep3}</p>
                    </div>

                    <form className="formV2 formV2-fieldset re-reset-password-form">
                        <Input
                            title={resx(RESX_GLOBAL, 'RESET_PASSWORD_FORM_PASSWORD')}
                            type="password"
                            name="Password"
                            id="Password"
                            dataTestid="Password"
                            required={true}
                            value={passwords.Password}
                            onChange={handleInputChange}
                            pattern=".{6,}"
                        />
                        <ErrorMessage validationMessages={validationMessages} fieldName="Password" />
                        <Input
                            title={resx(RESX_GLOBAL, 'RESET_PASSWORD_FORM_CONFIRM_PASSWORD')}
                            type="password"
                            name="ConfirmPassword"
                            id="ConfirmPassword"
                            dataTestid="ConfirmPassword"
                            required={true}
                            value={passwords.ConfirmPassword}
                            onChange={handleInputChange}
                            onBlur={handleInputChange}
                            pattern=".{6,}"
                        />
                        <ErrorMessage validationMessages={validationMessages} fieldName="ConfirmPassword" />
                    </form>
                    <div className="re-reset-password-btn">
                        <Button
                            className={`re-btn ${submitting ? 'submitting' : ''}`}
                            text="Save Password"
                            onClick={handleResetPassword}
                            dataTestid="restPasswordfFormnextStep"
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

PasswordForm.propTypes = {
    message: PropTypes.object
};

export default withMessage(PasswordForm);
