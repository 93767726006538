import React from 'react';
import PropTypes from 'prop-types';
import resx from '../../utilities/resx';
import { RESX_GLOBAL } from '../../Constants';

const Input = props => {
    let value;
    if (props.value) {
        value = typeof props.value === 'number' ? props.value.toString() : props.value;
    } else {
        value = '';
    }
    return (
        <React.Fragment>
            <label htmlFor={props.name} className={`re-label-conatiner ${props.disabled ? 're-label-disabled' : ''}`}>
                <span>{props.title}</span>
                {props.isOptional ? (
                    <span className="re-label-optional">{resx(RESX_GLOBAL, 'OPTIONAL_FIELD')}</span>
                ) : null}
            </label>
            <input
                className={props.className}
                data-testid={props.dataTestid}
                disabled={props.disabled}
                id={props.id || props.name}
                max={props.max}
                maxLength={props.maxlength}
                min={props.min}
                minLength={props.minlength}
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
                pattern={props.pattern} // eslint-disable-line
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                required={props.required}
                step={props.step ? props.step : undefined}
                title={props.title}
                type={props.type}
                value={value}
            />
        </React.Fragment>
    );
};

Input.propTypes = {
    className: PropTypes.string,
    dataTestid: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    isOptional: PropTypes.bool,
    minlength: PropTypes.number,
    maxlength: PropTypes.number,
    max: PropTypes.string,
    min: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    pattern: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    step: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Input.defaultProps = {
    type: 'text'
};

export default Input;
