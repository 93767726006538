import request from './AxiosRequest';

const requestPasswordChange = (data) =>
	request({
		url: '/Services/AuthService.svc/json/RequestPasswordChange',
		method: 'POST',
		data
	});

const addSignIn = (data) =>
	request({
		url: '/Services/AuthService.svc/json/SignIn',
		method: 'POST',
		data
	});

const AuthService = {
	requestPasswordChange,
	addSignIn
}

export default AuthService;