import React from 'react';
import PropTypes from 'prop-types';

const errorMessage = props => {
	if (props.validationMessages[props.fieldName]) {
		return (
			<div data-testid={`error_${props.fieldName}`} className="error error-message">
				{props.validationMessages[props.fieldName]}
			</div>
		);
	}
	return null;
};

errorMessage.propTypes = {
	validationMessages: PropTypes.object,
	fieldName: PropTypes.string
};

export default errorMessage;
